let playPromise: Promise<any>

const pause = (videoEl: HTMLVideoElement) => {
  if (playPromise) {
    playPromise
      .then(() => videoEl.pause())
      .catch(() => {
        console.log('Unable to play video')
      })
  } else {
    videoEl.pause()
  }
}

const play = (videoEl: HTMLVideoElement) => {
  if (playPromise) {
    playPromise
      .then(() => videoEl.play())
      .catch(() => {
        console.log('Unable to play video')
      })
  } else {
    playPromise = videoEl.play()
  }
}

const videoPlayback = (
  ref: React.RefObject<HTMLVideoElement>,
  playing: boolean
) => {
  let videoEl: HTMLVideoElement | null = ref.current

  if (!videoEl) {
    return
  }

  if (playing) {
    play(videoEl)
  } else {
    pause(videoEl)
  }
}

export default videoPlayback
