export const IMAGE_PORTRAIT_MAX_HEIGHT = 1280
export const IMAGE_LANDSCAPE_MAX_WIDTH = 1600

type Props = {
  height?: number
  image: {
    contentType: string
    details: {
      image: {
        height: number
        width: number
      }
    }
    url: string
  }
  width?: number
}

export const imgSrc = (props: Props) => {
  const {
    height,
    image: {
      contentType,
      details: {
        image: { height: sourceHeight, width: sourceWidth },
      },
      url,
    },
    width,
  } = props

  const newUrl = new URL(`https://${url}`)
  const params = new URLSearchParams()

  if (contentType === 'image/gif') {
    return url
  }

  if (sourceWidth >= sourceHeight) {
    const targetWidth = width || IMAGE_LANDSCAPE_MAX_WIDTH
    if (sourceWidth > targetWidth) {
      params.append('w', Math.round(targetWidth))
    }
  } else {
    const targetHeight = height || IMAGE_PORTRAIT_MAX_HEIGHT
    if (sourceHeight > targetHeight) {
      params.append('h', Math.round(targetHeight))
    }
  }

  params.append('fm', 'jpg')

  params.append('q', '75')

  // Assign params to constructed URL
  newUrl.search = params

  return newUrl.toString()
}
