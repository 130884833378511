import { useEffect, useState } from 'react'

const useOnScreen = (ref: React.RefObject<HTMLElement>, rootMargin = '0px') => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const handleIntersecting = ([entry]) => {
      // Update state when observer callback fires
      setIntersecting(entry.isIntersecting)
    }

    const observer = new IntersectionObserver(handleIntersecting, {
      rootMargin,
    })

    if (ref.current) {
      observer.observe(ref.current)
    }

    // Stop observing on unmount
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting
}

export default useOnScreen
