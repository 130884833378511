import React, { useState } from 'react'
import styled from 'styled-components'
import { ContentfulClient } from '../../typings/graphql'
import { richTextFromNode } from '../../utils/richText'
import Gallery from '../Gallery/Gallery'
import RichText from '../RichText/RichText'
import ToggleLink from '../../styled/ToggleLink'
import { minWidth } from '../../styled/theme'

interface Props {
  client: ContentfulClient
}

const Caption = styled.div`
  width: ${props =>
    `calc(100% - ${props.theme.margin.left} - ${props.theme.margin.right})`};
  max-width: 450px;
  margin-bottom: 0;
  margin-top: -50px;
  margin-left: ${props => props.theme.margin.left};
  margin-right: ${props => props.theme.margin.right};
  font-size: 13px;
  line-height: 1rem;
  opacity: 0.6;

  ${minWidth.small`
    width: auto;
  `};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 95px;
  padding-bottom: 95px;
  user-select: none;

  &:first-child {
    padding-top: 0;
  }

  ${minWidth.small`
    padding-top: 125px;
    padding-bottom: 125px;
  `}
`

const Item = React.forwardRef((props: Props, ref) => {
  const {
    client: { caption, id, information, media },
  } = props
  const captionRichText = richTextFromNode(caption)
  const informationRichText = richTextFromNode(information)

  // Don't render if no media is found
  if (!media || media.length === 0) {
    return false
  }

  return (
    <Container ref={ref}>
      <Gallery id={id} items={media} />
      {captionRichText && (
        <Caption>
          <RichText document={captionRichText} inlineLastParagraph={true} />
          <RichText document={informationRichText} inlineLastParagraph={true} />
        </Caption>
      )}
    </Container>
  )
})

export default Item
