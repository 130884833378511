import React, { useRef, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'
import ClientNav from '../ClientNav/ClientNav'
import RichText from '../RichText/RichText'
import useViewportScroll from '../../hooks/useViewportScroll'
import { activeHoverState } from '../../styled/theme'
import { ContentfulClient, ContentfulSettings } from '../../typings/graphql'
import { richTextFromNode } from '../../utils/richText'
import { scrollTo } from '../../utils/window'

interface Props {
  clients: Array<ContentfulClient>
  settings: ContentfulSettings
}

const AnimatedText = styled(animated.div)`
  /* margin-top: 1em; */
`

const Arrow = styled(animated.div)`
  position: absolute;
  margin-left: -1.1em;
  cursor: pointer;

  ${activeHoverState(`
    opacity: 0.7 !important;
  `)};
`

const Container = styled.div`
  z-index: ${props => props.theme.zIndex.header};
  position: fixed;
  top: ${props => props.theme.margin.top};
  left: ${props => props.theme.margin.left};
  right: ${props => props.theme.margin.right};
  height: 0;
  user-select: none;
`

const Information = styled.div``

const Logo = styled.div`
  display: table;
`

const Name = styled(animated.div)``

const Header = (props: Props) => {
  const { clients, settings } = props

  const ref = useRef(null)

  const [informationVisible, setInformationVisible] = useState(false)

  const informationRichText = richTextFromNode(settings.information)
  const viewportScrollPercent = useViewportScroll()

  const textVisible = viewportScrollPercent <= 0.15
  const textSpring = useSpring({
    opacity: Number(textVisible),
    pointerEvents: textVisible ? 'auto' : 'none',
  })

  const h1Spring = useSpring({
    transform: textVisible ? 'translateX(0em)' : 'translateX(1.1em)',
    willChange: 'transform' /* Required to prevent flickering on Safari */,
  })

  // TODO: `cursor` + `pointer-events` changes are defined here due to odd flickering occuring in FF - revisit
  const arrowSpring = useSpring({
    cursor: textVisible ? 'text' : 'pointer',
    pointerEvents: textVisible ? 'none' : 'auto',
    opacity: textVisible ? 0 : 1,
  })

  const nameSpring = useSpring({
    opacity: textVisible ? 1 : 0,
  })

  return (
    <Container>
      {/* Logo */}
      <Logo ref={ref}>
        <animated.h1 onTouchStart={() => {}} style={h1Spring}>
          <Arrow onClick={() => scrollTo(0)} style={arrowSpring}>
            ↑
          </Arrow>
          <Name style={nameSpring}>Robin Pyon</Name>
        </animated.h1>
      </Logo>

      <AnimatedText style={textSpring}>
        {/* Information Rich Text */}
        <Information>
          <RichText document={informationRichText} />
        </Information>

        {/* Client nav */}
        {clients && <ClientNav items={clients} />}
      </AnimatedText>
    </Container>
  )
}

export default Header
