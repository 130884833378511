import { css } from 'styled-components'

const theme = {
  breakpoints: {
    width: {
      small: 640,
      medium: 1020,
    },
  },
  color: {
    background: '#fff',
    highlight: '#666',
    text: '#000',
  },
  image: {
    width: {
      landscape: '100%',
      portrait: '60%',
      square: '80%',
    },
    height: {
      landscape: '360px',
      portrait: '500px',
      square: '320px',
    },
  },
  margin: {
    top: '18px',
    bottom: '18px',
    left: '20px',
    right: '20px',
  },
  zIndex: {
    modalOverlay: 13,
    modalItem: 12,
    modalBackground: 11,
    projectsModal: 4,
    header: 3,
    previewImages: 1,
    projects: 1,
  },
}

export const activeHoverState = (hoverState: string) => {
  return css`
    .modernizr-no-touchevents & {
      &:hover {
        ${hoverState};
      }
    }
    .modernizr-touchevents & {
      &:active {
        ${hoverState};
      }
    }
  `
}

/*
export const minHeight = Object.keys(theme.breakpoints.height).reduce(
  (acc, label) => {
    const breakpoint = theme.breakpoints.height[label]

    acc[label] = (...args) => css`
      @media (min-height: ${breakpoint}px) {
        ${css(...args)}
      }
    `

    return acc
  },
  {}
)
*/

export const minWidth = Object.keys(theme.breakpoints.width).reduce(
  (acc, label) => {
    const breakpoint = theme.breakpoints.width[label]

    acc[label] = (...args) => css`
      @media (min-width: ${breakpoint}px) {
        ${css(...args)}
      }
    `

    return acc
  },
  {}
)

export default theme
