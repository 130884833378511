import React, { useContext } from 'react'
import styled from 'styled-components'
import { ClientsContext } from '../../contexts/Clients'
import useLockHtmlScroll from '../../hooks/useLockHtmlScroll'

const Container = styled.div`
  z-index: ${props => props.theme.zIndex.modalOverlay};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: zoom-out;
`

const ModalOverlay = () => {
  const { dispatch } = useContext(ClientsContext)
  useLockHtmlScroll()

  const handleClick = () => {
    dispatch({
      type: 'MODAL_GALLERY_MEDIA_ID_CLEAR',
    })
  }

  return <Container onClick={handleClick} />
}

export default ModalOverlay
