import React from 'react'
import styled, { keyframes } from 'styled-components'

interface Props {
  style?: Object
}

const cycle = keyframes`
  0% {
    opacity: 0.3;
  }

  40% {
    opacity: 1;
  }

  80% {
    opacity: 0.3;
  }

  100% {
    opacity: 0.3;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 20px;
  background: #111;
  border-radius: 4px;
`

const Dot = styled.div`
  animation-timing-function: ease-in-out;
  animation-duration: 2.5s;
  animation-name: ${cycle};
  animation-iteration-count: infinite;
  width: 4px;
  height: 4px;
  margin: 0 2px;
  background: #ddd;
  border-radius: 4px;

  &:nth-child(1) {
    animation-delay: 0;
  }

  &:nth-child(2) {
    animation-delay: 200ms;
  }

  &:nth-child(3) {
    animation-delay: 400ms;
  }
`

const LoaderDots = (props: Props) => {
  const { style } = props
  return (
    <Container style={style}>
      <Dot />
      <Dot />
      <Dot />
    </Container>
  )
}

export default LoaderDots
