import { useLayoutEffect } from 'react'

const useLockHtmlScroll = () => {
  const handleTouchMove = e => {
    e.preventDefault()
  }

  useLayoutEffect(() => {
    // (iOS hack) Prevent touchmove on document
    document.addEventListener('touchmove', handleTouchMove, { passive: false })

    // Prevent CSS scrolling on mount
    document.documentElement.style.overflow = 'hidden'

    return () => {
      // (iOS hack) Re-enable touchmove on document
      document.removeEventListener('touchmove', handleTouchMove)

      // Re-enable CSS scrolling when component unmounts
      document.documentElement.style.overflow = 'visible'
    }
  }, []) // Empty array ensures effect is only run on mount and unmount
}

export default useLockHtmlScroll
