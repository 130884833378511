import { useEffect, useState } from 'react'

let image: HTMLImageElement

const useImageLoaded = (src: string, load: boolean = true) => {
  const [isLoaded, setLoaded] = useState(false)

  const handleImageLoaded = () => {
    setLoaded(true)
  }

  useEffect(() => {
    if (!isLoaded) {
      image = new Image()
      image.addEventListener('load', handleImageLoaded)
    }

    if (load && !isLoaded) {
      image.src = src
    }

    return () => {
      if (image) {
        image.removeEventListener('load', handleImageLoaded)
      }
    }
  }, [load]) // Use effect on mount, unmount and when `load` changes

  return isLoaded
}

export default useImageLoaded
