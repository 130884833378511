import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { pathOr } from 'ramda'
import useImageLoaded from '../../hooks/useImageLoaded'
import { ContentfulAsset } from '../../typings/graphql'
import { imgSrc } from '../../utils/contentful'
import { minWidth } from '../../styled/theme'

interface Props {
  image: ContentfulAsset
  onClic: Function
  preload: boolean
  visible: boolean
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const BackgroundImage = styled.div`
  animation-name: ${fadeIn};
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: center / cover no-repeat url("${(props: { src: string }) =>
    props.src}");
  border: 1px solid ${props => props.theme.color.background};
`

const Container = styled.div`
  transform: translate3d(-50%, -50%, 0);
  z-index: ${props => props.theme.zIndex.header};
  position: fixed;
  top: 50vh;
  left: 50%;
  opacity: ${props => (props.visible ? 1 : 0)};
  pointer-events: ${props => (props.visible ? 'auto' : 'none')};

  ${props => css`
    ${props.aspect < 1 &&
      /* Portrait */
      css`
        width: 25vmin;
        max-width: 200px;
        height: ${25 / props.aspect}vmin;
        max-height: ${200 / props.aspect}px;
      `}

    ${props.aspect === 1 &&
      /* Square */
      css`
        width: 30vmin;
        max-width: 250px;
        height: 30vmin;
        max-height: 250px;
      `}

    ${props.aspect > 1 &&
      /* Landscape */
      css`
        width: 45vmin;
        max-width: 300px;
        height: ${45 / props.aspect}vmin;
        max-height: ${300 / props.aspect}px;
      `}
  `}

  ${props => minWidth.small`
    ${props.aspect < 1 &&
      /* Portrait */
      css`
        max-width: 200px;
        max-height: ${200 / props.aspect}px;
      `}

    ${props.aspect === 1 &&
      /* Square */
      css`
        max-width: 250px;
        max-height: 250px;
      `}

    ${props.aspect > 1 &&
      /* Landscape */
      css`
        max-width: 300px;
        max-height: ${300 / props.aspect}px;
      `}
  `}
`

const PreviewImagesItem = (props: Props) => {
  const { image, onClick, preload, visible } = props
  const file = pathOr({}, ['file'], image)
  const aspect = file.details.image.width / file.details.image.height

  const imageUrl = imgSrc({
    height: 400,
    image: image.file,
    width: 600,
  })

  // Load image immediately and return load state
  const imageLoaded = useImageLoaded(imageUrl, preload)

  return (
    <Container aspect={aspect} onClick={onClick} visible={visible}>
      {imageLoaded && <BackgroundImage src={imageUrl} />}
    </Container>
  )
}

export default React.memo(PreviewImagesItem)
