import _shuffle from 'lodash.shuffle'
import { includes } from 'ramda'
import React, { useState, useEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'
import useViewportScroll from '../../hooks/useViewportScroll'
import { ContentfulAsset } from '../../typings/graphql'
import PreviewImagesItem from './Item'

interface Props {
  images: Array<ContentfulAsset>
}

const SpringContainer = styled(animated.div)`
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
`

const PreviewImages = (props: Props) => {
  const { images } = props

  const [currentIndex, setCurrentIndex] = useState(0)
  const [preloadIndex, setPreloadIndex] = useState(3)
  const [shuffledImages, setShuffledImages] = useState([])

  const viewportScrollPercent = useViewportScroll()

  const previewVisible = viewportScrollPercent <= 0.15

  const springOptions = {
    config: {
      friction: 40,
      mass: 1,
      tension: 170,
    },
    opacity: Number(previewVisible),
  }

  const previewSpring = useSpring(springOptions)

  // Shuffle images on initial mount
  useEffect(() => {
    setShuffledImages(_shuffle(images))
  }, [])

  const handleClick = () => {
    const numImages = shuffledImages.length

    setCurrentIndex(currentIndex < numImages - 1 ? currentIndex + 1 : 0)

    setPreloadIndex(
      preloadIndex < numImages - 1 ? preloadIndex + 1 : numImages - 1
    )
  }

  return (
    <SpringContainer onClick={handleClick} style={previewSpring}>
      {shuffledImages &&
        shuffledImages.map((image, index) => {
          return (
            <PreviewImagesItem
              key={index}
              image={image}
              preload={index <= preloadIndex}
              visible={currentIndex === index}
            />
          )
        })}
    </SpringContainer>
  )
}

export default PreviewImages
