import styled, { css } from 'styled-components'
import { activeHoverState } from './theme'

const ExternalLink = styled.a`
  color: ${props => props.theme.color.text};
  text-decoration: none;
  cursor: pointer;

  background-image: ${props =>
    `linear-gradient(to right, ${
      props.theme.color.text
    } 50%, transparent 50%)`};
  background-position: 0 calc(1.1em);
  background-repeat: repeat-x;
  background-size: 2px 1px;

  ${activeHoverState(css`
    color: ${props => props.theme.color.highlight};
    background-image: ${props =>
      `linear-gradient(to right, ${
        props.theme.color.highlight
      } 50%, transparent 50%)`};
  `)}
`

export default ExternalLink
