import React, { useContext } from 'react'
import styled from 'styled-components'
import { minWidth } from '../../styled/theme'
import { ContentfulClient } from '../../typings/graphql'
import { nonNullClients } from '../../utils/clients'
import { ClientsContext } from '../../contexts/Clients'
import ModalBackground from '../Modal/Background'
import ModalOverlay from '../Modal/Overlay'
import Item from './Item'

interface Props {
  clients: Array<ContentfulClient>
}

const Container = styled.div`
  z-index: ${props =>
    props.modal
      ? props.theme.zIndex.projectsModal
      : props.theme.zIndex.projects};
  position: relative;
  margin-top: 100vh;
  margin-bottom: 10vh;
`

const ClientProjects = (props: Props) => {
  const { clients } = props
  const filteredClients = nonNullClients(clients)
  const { dispatch, state } = useContext(ClientsContext)

  // Render nothing if no items are found
  if (!filteredClients || filteredClients.length === 0) {
    return false
  }

  return (
    <Container modal={state.mediaId}>
      {state.mediaId && <ModalBackground />}

      {filteredClients.map(client => {
        const { id } = client
        const ref = state.projectRefs[id].ref
        return <Item client={client} key={id} ref={ref} />
      })}

      {state.mediaId && <ModalOverlay />}
    </Container>
  )
}

export default ClientProjects
