import React, { useContext, useRef } from 'react'
import { animated, useSpring } from 'react-spring'
import styled, { css } from 'styled-components'
import { pathOr } from 'ramda'
import { ClientsContext } from '../../contexts/Clients'
import useImageLoaded from '../../hooks/useImageLoaded'
import useOnScreen from '../../hooks/useOnScreen'
import { ContentfulImage } from '../../typings/graphql'
import { imgSrc } from '../../utils/contentful'
import LoaderDisc from '../Loader/Disc'

interface Props {
  image: ContentfulImage
}

const BackgroundImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 1280px;
  max-height: 720px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: center / contain no-repeat url("${(props: { src: string }) =>
    props.src}");
`

type ContainerProps = {
  image: string
}
const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: ${props => (props.showZoomInCursor ? 'zoom-in' : 'default')};

  ${props =>
    props.zoomedIn &&
    css`
      z-index: ${props => props.theme.zIndex.modalItem};
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: calc(100vw - 40px);
      height: calc(100vh - 40px);
      margin: auto;
    `};
`

const Image = (props: Props) => {
  // console.log('Image.render()', props)

  const { image } = props
  const file = pathOr({}, ['image', 'file'], image)

  const { dispatch, state } = useContext(ClientsContext)

  const ref = useRef(null)
  const inViewport = useOnScreen(ref, '300px')

  const imageUrl = imgSrc({
    image: file,
  })

  // Load image when component is on screen
  const imageLoaded = useImageLoaded(imageUrl, inViewport)

  const imageProps = useSpring({
    opacity: Number(imageLoaded),
  })

  const zoomedIn = state.mediaId === image.id

  const handleClick = () => {
    dispatch({
      payload: image.id,
      type: 'MODAL_GALLERY_MEDIA_ID_SET',
    })
  }

  return (
    <Container
      onClick={imageLoaded ? handleClick : undefined}
      showZoomInCursor={imageLoaded}
      ref={ref}
      zoomedIn={zoomedIn}
    >
      {/* Main image */}
      <animated.div style={imageProps}>
        <BackgroundImage src={imageLoaded ? imageUrl : ''} />
      </animated.div>

      {/* Image preloader */}
      <LoaderDisc
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
        }}
        visible={!imageLoaded}
      />
    </Container>
  )
}

export default Image
