import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  z-index: ${props => props.theme.zIndex.modalBackground};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.color.background};
  opacity: 1;
`

const ModalBackground = () => {
  return <Container />
}

export default ModalBackground
