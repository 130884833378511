import React, { useReducer } from 'react'

const ClientsContext = React.createContext(null)

/* Reducer */

const reducer = (state, action) => {
  switch (action.type) {
    case 'MODAL_GALLERY_MEDIA_ID_CLEAR':
      return {
        ...state,
        mediaId: null,
      }
    case 'MODAL_GALLERY_MEDIA_ID_SET':
      return {
        ...state,
        mediaId: action.payload,
      }
  }
}

/* Provider */

interface Props {
  children: React.ReactNode
  initialState: Object
}

const ClientsContextProvider = (props: Props) => {
  const { initialState } = props

  const [state, dispatch] = useReducer(reducer, initialState)
  const value = { state, dispatch }

  const { children } = props

  return (
    <ClientsContext.Provider value={value}>{children}</ClientsContext.Provider>
  )
}

export { ClientsContext, ClientsContextProvider }
