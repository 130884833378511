import React from 'react'
import styled, { keyframes } from 'styled-components'
import { animated, useTransition } from 'react-spring'

interface Props {
  style?: any
  visible: boolean
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Svg = styled.svg`
  animation: ${rotate} 5s linear infinite;
  display: block;
  margin-left: -8px;
  margin-top: -8px;
`

const Disc = (props: Props) => {
  const { style, visible } = props

  const items = visible ? [{}] : []

  const transitions = useTransition(items, '', {
    delay: 1000,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <>
      {transitions.map(({ key, props }) => {
        return (
          <animated.div key={key} style={props}>
            <Svg style={style} width="16px" height="16px" viewBox="0 0 16 16">
              <g stroke="none" fill="none" opacity="1.0">
                <g
                  transform="translate(-1049.000000, -253.000000)"
                  stroke="#000000"
                >
                  <path
                    d="M1057.03204,267.999927 C1060.89799,268.017345 1064.01762,264.897491 1063.99993,261.031538 C1063.98223,257.165585 1060.83391,254.01749 1056.96796,254.000073 C1053.10201,253.982655 1049.98238,257.102509 1050.00007,260.968462"
                    id="Oval"
                    transform="translate(1057.000000, 261.000000) rotate(-90.000000) translate(-1057.000000, -261.000000) "
                  />
                </g>
              </g>
            </Svg>
          </animated.div>
        )
      })}
    </>
  )
}

export default Disc
