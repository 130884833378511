import React from 'react'
import { graphql } from 'gatsby'
import { indexBy, isEmpty, map, path, pathOr, prop } from 'ramda'

import ClientProjects from '../components/ClientProjects/ClientProjects'
import DownArrow from '../components/DownArrow/DownArrow'
import Header from '../components/Header/Header'
import Layout from '../components/Layout/Layout'
import PreviewImages from '../components/PreviewImages/PreviewImages'
import SEO from '../components/SEO/SEO'
import { ClientsContextProvider } from '../contexts/Clients'
import { ContentfulSettings } from '../typings/graphql'

interface Props {
  data: {
    allContentfulSettings: {
      edges: Array<ContentfulSettings>
    }
  }
  location: Location
}

let refs = {}

const IndexPage = (props: Props) => {
  const { location } = props

  const settings: ContentfulSettings = pathOr(
    undefined,
    ['data', 'allContentfulSettings', 'edges', 0, 'node'],
    props
  )

  const clients = settings.clients

  if (isEmpty(refs)) {
    // TOOD: refactor
    refs = indexBy(
      prop('id'),
      map(
        i => ({
          ...i,
          ref: React.createRef(),
        }),
        clients
      )
    )
  }

  if (!clients) {
    return false
  }

  return (
    <Layout>
      {/* SEO */}
      <SEO
        description={path(['siteDescription', 'content'], settings)}
        pathname={location.pathname}
        title={path(['siteTitle'], settings)}
      />

      <ClientsContextProvider
        initialState={{
          projectRefs: refs,
        }}
      >
        {/* Preview Images */}
        <PreviewImages images={settings.previewImages} />

        {/* Client projects */}
        <ClientProjects clients={clients} />

        {/* Header */}
        <Header clients={clients} settings={settings} />

        {/* Down Arrow */}
        <DownArrow />
      </ClientsContextProvider>
    </Layout>
  )
}

export default IndexPage

export const indexQuery = graphql`
  query {
    allContentfulSettings {
      edges {
        node {
          clients {
            caption {
              internal {
                content
              }
            }
            id
            information {
              internal {
                content
              }
            }
            name
            media {
              ... on ContentfulImage {
                id
                image {
                  file {
                    contentType
                    details {
                      image {
                        height
                        width
                      }
                    }
                    fileName
                    url
                  }
                }
                internal {
                  type
                }
                size
              }
              ... on ContentfulVideo {
                id
                image {
                  file {
                    contentType
                    details {
                      image {
                        height
                        width
                      }
                    }
                    fileName
                    url
                  }
                }
                internal {
                  type
                }
                size
                video {
                  file {
                    contentType
                    fileName
                    url
                  }
                }
              }
            }
          }
          information {
            internal {
              content
            }
          }
          previewImages {
            file {
              contentType
              details {
                image {
                  height
                  width
                }
              }
              fileName
              url
            }
          }
          siteDescription {
            content: siteDescription
          }
          siteTitle
        }
      }
    }
  }
`
