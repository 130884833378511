import React, { useContext } from 'react'
import styled from 'styled-components'
import { ClientsContext } from '../../contexts/Clients'
import ProjectLink from '../../styled/ProjectLink'
import { minWidth } from '../../styled/theme'
import { ContentfulClient } from '../../typings/graphql'
import { nonNullClients } from '../../utils/clients'
import { scrollToRef } from '../../utils/window'

interface Props {
  items: Array<ContentfulClient>
}

const Container = styled.div`
  margin-top: 1.25em;
  /* white-space: pre-wrap; */
`

const Punctuation = styled.span``

const ClientNav = (props: Props) => {
  const { items } = props

  const clients = nonNullClients(items)
  const { state } = useContext(ClientsContext)

  if (!clients) {
    return false
  }

  const handleClick = (id: string) => {
    const targetRef = state.projectRefs[id].ref
    scrollToRef(targetRef)
  }

  return (
    <Container>
      {clients.map((client, index) => {
        const { id, name } = client
        const lastChild = index === clients.length - 1
        const onlyChild = index === 0 && clients.length === 1
        const secondLastChild = index === clients.length - 2

        // TODO: refactor
        return (
          <span key={id}>
            {!onlyChild && lastChild && 'and '}
            <ProjectLink
              onClick={() => handleClick(id)}
              onTouchStart={() => {}}
            >
              {name}
            </ProjectLink>
            {!onlyChild && !lastChild && !secondLastChild && ', '}
            {!onlyChild && secondLastChild && ' '}
          </span>
        )
      })}
    </Container>
  )
}

export default ClientNav
