import React from 'react'
import { isEmailUrl } from '../../utils/string'
import ExternalLink from '../../styled/ExternalLink'

interface Props {
  children: React.ReactNode
  href: string
}

const RichTextLink = (props: Props) => {
  const { children, href } = props
  const target = isEmailUrl(href) ? '_self' : '_blank'
  return (
    <ExternalLink href={href} target={target}>
      {children}
    </ExternalLink>
  )
}

export default RichTextLink
