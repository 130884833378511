import React from 'react'
import styled from 'styled-components'
import RichTextToReact from 'rich-text-to-react'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { minWidth } from '../../styled/theme'
import RichTextLink from './RichTextLink'

interface Props {
  codeCallback?: () => void
  document: any
  indentParagraphs?: boolean
  inlineLastParagraph?: boolean
}

const P = styled.p`
  margin: 0;
  white-space: pre-wrap;

  &:last-of-type {
    display: ${props => (props.inlineLastParagraph ? 'inline' : 'block')};
    margin-right: 7px;
  }
`

const RichText = (props: Props) => {
  const { document } = props

  const RICH_TEXT_OPTIONS = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: function renderBlockParagraph(
        node: any,
        key: string
      ) {
        return (
          <P
            inlineLastParagraph={props.inlineLastParagraph}
            indentParagraphs={props.indentParagraphs}
            key={key}
          >
            <RichTextToReact document={node} options={RICH_TEXT_OPTIONS} />
          </P>
        )
      },
      [INLINES.HYPERLINK]: function renderInlineHyperlink(
        node: any,
        key: string
      ) {
        const { data } = node
        const uri = data.uri
        return (
          <RichTextLink href={uri} key={key}>
            <RichTextToReact document={node} options={RICH_TEXT_OPTIONS} />
          </RichTextLink>
        )
      },
    },
  }

  return <RichTextToReact document={document} options={RICH_TEXT_OPTIONS} />
}

export default RichText
