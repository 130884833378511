import React from 'react'
import styled, { css } from 'styled-components'
import { compose, equals, pathOr } from 'ramda'
import { minWidth } from '../../styled/theme'
import { ContentfulImage, ContentfulVideo } from '../../typings/graphql'
import Image from '../Image/Image'
import Video from '../Video/Video'

interface Props {
  id: string
  items: Array<ContentfulImage | ContentfulVideo>
}

const Container = styled.div`
  display: inline-flex;
  width: ${props =>
    `calc(100% - ${props.theme.margin.left} - ${props.theme.margin.right})`};
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  white-space: nowrap;
  vertical-align: bottom;

  ${minWidth.small`
    width: 100%;
    flex-direction: row;
    margin: 0;
    padding-top: 0;
  `};
`

type WrapperProps = {
  aspect: number
}
const Wrapper = styled.div`
  position: relative;
  display: table;
  margin-bottom: 90px;

  ${(props: WrapperProps) =>
    props.aspect < 1 &&
    /* Portrait */
    css`
      width: ${65 * props.multiplier}%;
      height: 0;
      padding-bottom: ${(65 * props.multiplier) / props.aspect}%;
    `}

  ${(props: WrapperProps) =>
    props.aspect === 1 &&
    /* Square */
    css`
      width: ${80 * props.multiplier}%;
      height: 0;
      padding-bottom: ${(80 * props.multiplier) / props.aspect}%;
    `}

  ${(props: WrapperProps) =>
    props.aspect > 1 &&
    /* Landscape */
    css`
      width: ${100 * props.multiplier}%;
      height: 0;
      padding-bottom: ${(100 * props.multiplier) / props.aspect}%;
    `}

  ${(props: WrapperProps) => minWidth.small`
    display: inline-block;
    margin-right: 120px;
    margin-left: ${props.theme.margin.left};
    margin-right: 80px;
    margin-bottom: 90px;
    padding-bottom: 0;
    vertical-align: bottom;

    ${props.aspect < 1 &&
      /* Portrait */
      css`
        height: ${350 * props.multiplier}px;
        width: ${350 * props.multiplier * props.aspect}px;
      `}

    ${props.aspect === 1 &&
      /* Square */
      css`
        height: ${325 * props.multiplier}px;
        width: ${325 * props.multiplier}px;
      `}

    ${props.aspect > 1 &&
      /* Landscape */
      css`
        height: ${280 * props.multiplier}px;
        width: ${280 * props.multiplier * props.aspect}px;
      `}
  `}
`

const pathInternalContentType = pathOr('', ['internal', 'type'])

function isImage(
  item: ContentfulImage | ContentfulVideo
): item is ContentfulImage {
  return compose(
    equals('ContentfulImage'),
    pathInternalContentType
  )(item)
}

function isVideo(
  item: ContentfulImage | ContentfulVideo
): item is ContentfulImage {
  return compose(
    equals('ContentfulVideo'),
    pathInternalContentType
  )(item)
}

const Media = (props: Props) => {
  const { id, items } = props

  // Render nothing if no items are found
  if (!items || items.length === 0) {
    return null
  }

  return (
    <Container>
      {items.map((item, index) => {
        const key = `${id}--${item.id}--${index}`

        if (isImage(item)) {
          const file = pathOr({}, ['image', 'file'], item)
          const aspect = file.details.image.width / file.details.image.height
          const multiplier = item.size === 'large' ? 1 : 0.7

          return (
            <Wrapper aspect={aspect} key={key} multiplier={multiplier}>
              <Image image={item} />
            </Wrapper>
          )
        }
        if (isVideo(item)) {
          const file = pathOr({}, ['image', 'file'], item)
          const aspect = file.details.image.width / file.details.image.height
          const multiplier = item.size === 'large' ? 1 : 0.7

          return (
            <Wrapper aspect={aspect} key={key} multiplier={multiplier}>
              <Video aspect={aspect} video={item} />
            </Wrapper>
          )
        }
        return null
      })}
    </Container>
  )
}

export default Media
