import React from 'react'
import { animated, useSpring } from 'react-spring'
import styled from 'styled-components'
import useViewportScroll from '../../hooks/useViewportScroll'
import { activeHoverState } from '../../styled/theme'
import { scrollTo } from '../../utils/window'

const Arrow = styled.div`
  transform: rotate(180deg);
  ${activeHoverState(`
    opacity: 0.7 !important;
  `)};
`

const SpringContainer = styled(animated.div)`
  position: fixed;
  bottom: ${props => props.theme.margin.bottom};
  left: ${props => props.theme.margin.left};
  cursor: pointer;
`

const DownArrow = () => {
  const viewportScrollPercent = useViewportScroll()
  const visible = viewportScrollPercent <= 0
  const spring = useSpring({
    opacity: Number(visible),
    pointerEvents: visible ? 'auto' : 'none',
  })

  return (
    <SpringContainer
      onClick={() => {
        const target = window.innerHeight
        scrollTo(target)
      }}
      style={spring}
    >
      <Arrow>↑</Arrow>
    </SpringContainer>
  )
}

export default DownArrow
