const scrollTo = (num: number, offset: number = 0) => {
  window.scrollTo({
    top: num + offset,
    behavior: 'smooth',
  })
}
const scrollToRef = (ref: React.RefObject<HTMLElement>, offset: number = 0) => {
  if (ref.current) {
    window.scrollTo({
      top: ref.current.offsetTop + window.innerHeight + offset,
      behavior: 'smooth',
    })
  }
}

export { scrollTo, scrollToRef }
