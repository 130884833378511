import { useEffect, useState } from 'react'

const DELAY = 400
let timeout: number

const useVideoWaitingOrStalled = (ref: React.RefObject<HTMLVideoElement>) => {
  const [isWaitingOrStalled, setWaitingOrStalled] = useState(false)

  const handlePlaying = () => {
    clearTimeout(timeout)
    setWaitingOrStalled(false)
  }

  const handleStalled = () => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setWaitingOrStalled(true)
    }, DELAY)
  }

  const handleWaiting = () => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setWaitingOrStalled(true)
    }, DELAY)
  }

  useEffect(() => {
    let videoEl: HTMLVideoElement | null = ref.current

    if (!videoEl) {
      return
    }

    videoEl.addEventListener('playing', handlePlaying)
    videoEl.addEventListener('stalled', handleStalled)
    videoEl.addEventListener('waiting', handleWaiting)

    // Remove event listeners on unmount
    return () => {
      if (videoEl) {
        videoEl.removeEventListener('playing', handlePlaying)
        videoEl.removeEventListener('stalled', handleStalled)
        videoEl.removeEventListener('waiting', handleWaiting)
      }
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return isWaitingOrStalled
}

export default useVideoWaitingOrStalled
