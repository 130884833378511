import React from 'react'
import Helmet from 'react-helmet'
import { path, pathOr } from 'ramda'
import { graphql, StaticQuery } from 'gatsby'
import { ContentfulSettings } from '../../typings/graphql'

type Props = {
  description: string
  disableIndexing?: boolean
  image?: {
    height: number
    width: number
    src: string
  }
  pathname: string
  title: string
}

// TODO: type `siteMetadata` from `gatsby-config`
interface StaticQueryData {
  data: {
    allContentfulSettings: {
      edges: Array<ContentfulSettings>
    }
  }
}

const SEO = (props: Props) => {
  const { description, disableIndexing, image, pathname, title } = props
  return (
    <StaticQuery
      query={staticQuery}
      render={(data: StaticQueryData) => {
        const canonicalUrl = data.site.siteMetadata.siteUrl + pathname

        const settings: ContentfulSettings = pathOr(
          undefined,
          ['allContentfulSettings', 'edges', 0, 'node'],
          data
        )

        const siteImage = path(['siteImage'], settings)
        const fallbackImage = {
          height: siteImage.dimensions.height,
          src: siteImage.file.url + '?w=800',
          width: siteImage.dimensions.width,
        }

        return (
          <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={canonicalUrl} />

            {/* Disable indexing (optional) */}
            {disableIndexing && (
              <meta name="robots" content="noindex,nofollow" />
            )}

            {/* Open Graph */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta
              property="og:image"
              content={`https:${image ? image.src : fallbackImage.src}`}
            />
            <meta
              property="og:image:width"
              content={image ? image.width : fallbackImage.width}
            />
            <meta
              property="og:image:height"
              content={image ? image.height : fallbackImage.height}
            />
            <meta property="og:type" content="website" />

            {/* Twitter cards */}
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:card" content="summary" />
            <meta
              property="twitter:image"
              content={`https:${image ? image.src : fallbackImage.src}`}
            />
          </Helmet>
        )
      }}
    />
  )
}

export default SEO

const staticQuery = graphql`
  {
    allContentfulSettings {
      edges {
        node {
          siteImage {
            dimensions: fixed {
              width
              height
            }
            file {
              contentType
              url
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
