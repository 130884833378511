import { pathOr } from 'ramda'

const parseJSON = (str: string): Object => {
  try {
    return JSON.parse(str)
  } catch (err) {
    return Error('Failed to parse JSON')
  }
}

// TODO: correctly type Contentful RichText
export const richTextFromNode = (node: any) => {
  return parseJSON(pathOr('', ['internal', 'content'], node))
}
