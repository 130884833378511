import React, { useContext, useRef } from 'react'
import { animated, useSpring } from 'react-spring'
import styled, { css } from 'styled-components'
import { path } from 'ramda'
import { ClientsContext } from '../../contexts/Clients'
import useImageLoaded from '../../hooks/useImageLoaded'
import useOnScreen from '../../hooks/useOnScreen'
import usePageVisibility from '../../hooks/usePageVisibility'
import usePrevious from '../../hooks/usePrevious'
import useVideoWaitingOrStalled from '../../hooks/useVideoWaitingOrStalled'
import { ContentfulVideo } from '../../typings/graphql'
import { imgSrc } from '../../utils/contentful'
import videoPlayback from '../../utils/videoPlayback'
import LoaderDisc from '../Loader/Disc'
import LoaderDots from '../Loader/Dots'

interface Props {
  aspect: number
  video: ContentfulVideo
}

const Container = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  cursor: ${props => (props.showZoomInCursor ? 'zoom-in' : 'default')};

  ${props =>
    props.zoomedIn &&
    css`
      z-index: ${props => props.theme.zIndex.modalItem};
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: calc(100vw - 40px);
      height: calc(${100 / props.aspect}vw - ${40 / props.aspect}px);
      max-height: calc(100vh - 40px);
      max-width: calc(${100 * props.aspect}vh - ${40 * props.aspect}px);
      margin: auto;
    `};
`

const VideoContainer = styled.video.attrs({
  autoPlay: true,
  controls: false,
  muted: true,
  loop: true,
  playsInline: true,
})`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 1280px;
  max-height: 720px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const Video = (props: Props) => {
  const { aspect, video } = props
  const posterFile = path(['image', 'file'], video)
  const videoFile = path(['video', 'file'], video)

  const { dispatch, state } = useContext(ClientsContext)

  const refContainer = useRef(null)
  const refVideo = useRef(null)

  const inViewport = useOnScreen(refContainer, '0px')
  const inViewportExtended = useOnScreen(refContainer, '300px')

  // Get contentful image URL
  const posterUrl = imgSrc({
    image: posterFile,
  })

  // Get loaded status of poster image (loaded when when component is within viewport bounds)
  const imageLoaded = useImageLoaded(posterUrl, inViewportExtended)

  // Mark videos as ready to play when:
  // - their poster image has loaded
  // - it's in the viewport
  const videoPlaying = imageLoaded && inViewport

  const videoProps = useSpring({
    opacity: Number(imageLoaded),
  })

  // Check if video playback has stalled / waiting
  const videoWaitingOrStalled = useVideoWaitingOrStalled(refVideo)

  // Animate spring based on stalled / waiting status
  const videoWaitingSpring = useSpring({
    opacity: Number(imageLoaded && videoWaitingOrStalled),
  })

  // Check page visibility and store previous value
  const pageVisible = usePageVisibility()
  const previousPageVisible = usePrevious(pageVisible)

  // Play / resume video if:
  // - page is now visible (and previously wasnt)
  // - videos are marked as ready to play (poster loaded + in viewport)
  if (!previousPageVisible && pageVisible && videoPlaying) {
    videoPlayback(refVideo, true)
  }

  const zoomedIn = state.mediaId === video.id

  const handleClick = () => {
    dispatch({
      payload: video.id,
      type: 'MODAL_GALLERY_MEDIA_ID_SET',
    })
  }

  return (
    <Container
      aspect={aspect}
      onClick={videoPlaying ? handleClick : undefined}
      ref={refContainer}
      showZoomInCursor={videoPlaying}
      zoomedIn={zoomedIn}
    >
      {/* Main video */}
      <animated.div style={videoProps}>
        <VideoContainer
          poster={imageLoaded ? posterUrl : ''}
          ref={refVideo}
          src={videoPlaying ? videoFile.url : ''}
          style={videoProps}
        />
      </animated.div>

      {/* Video stalled / waiting display */}
      <animated.div style={videoWaitingSpring}>
        <LoaderDots
          style={{
            position: 'absolute',
            top: '7px',
            right: '7px',
          }}
        />
      </animated.div>

      {/* Poster image preloader */}
      <LoaderDisc
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
        }}
        visible={!imageLoaded}
      />
    </Container>
  )
}

export default Video
