// Based off @rehooks/window-scroll-position
// https://github.com/rehooks/window-scroll-position/blob/master/index.js

import { useLayoutEffect, useState } from 'react'
import _throttle from 'lodash.throttle'

let supportsPassive = false
try {
  var opts = Object.defineProperty({}, 'passive', {
    get: function() {
      supportsPassive = true
    },
  })
  window.addEventListener('testPassive', null, opts)
  window.removeEventListener('testPassive', null, opts)
} catch (e) {}

const getViewportPercent = () => {
  const percent = window.scrollY / window.innerHeight

  // TODO: check if is this faster than using a combination of Math.min + Math.max
  if (percent <= 0) {
    return 0
  } else if (percent > 1) {
    return 1
  } else {
    return percent
  }
}

const defaultOptions = {
  throttle: 100,
}

const useScrollPercent = (options?: { throttle: number }) => {
  let opts = Object.assign({}, defaultOptions, options)

  const [viewportScrollPercent, setViewportPercent] = useState(0)

  useLayoutEffect(() => {
    let handleScroll = _throttle(() => {
      setViewportPercent(getViewportPercent())
    }, opts.throttle)

    // Call immediately
    handleScroll()

    window.addEventListener(
      'scroll',
      handleScroll,
      supportsPassive ? { passive: true } : false
    )

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return viewportScrollPercent
}

export default useScrollPercent
