import { useEffect, useState } from 'react'

const usePageVisibility = () => {
  const [pageVisible, setPageVisible] = useState(true)

  useEffect(() => {
    const handleVisibilityChange = () => {
      setPageVisible(document.visibilityState === 'visible')
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return pageVisible
}

export default usePageVisibility
