import styled, { css } from 'styled-components'
import { activeHoverState, minWidth } from './theme'

const ProjectLink = styled.span`
  display: inline;
  color: inherit;
  text-decoration: none;
  user-select: none;
  cursor: pointer;

  ${activeHoverState(css`
    color: ${props => props.theme.color.highlight};
  `)}
`

export default ProjectLink
