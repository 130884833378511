import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import theme from '../../styled/theme'

type Props = {
  children: React.ReactNode
}

const GlobalStyle = createGlobalStyle`
  html {
    background: ${props => props.theme.color.background};
    color: ${props => props.theme.color.text};
  }
`

const Layout = (props: Props) => {
  const { children } = props
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        {children}
      </>
    </ThemeProvider>
  )
}

export default Layout
